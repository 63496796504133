import usuarioLoginStore from 'src/store/usuario-login/'
import axios from 'axios'
import { LocalStorage } from 'quasar'

export default {
  name: 'Logout',
  mounted () {
    if (!this.$store.hasModule('usuario-login')) this.$store.registerModule('usuario-login', usuarioLoginStore)
    this.$store.dispatch('usuario-login/logoutUsuario')
    axios.defaults.headers.common.Authorization = ''
    LocalStorage.remove('usuarioSelecionado')
    LocalStorage.remove('logado')
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }
}
